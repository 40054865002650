import React from 'react';
import { Link } from 'gatsby';

import styles from '../styles/footer.module.scss';

import av from '../downloads/SGWB-reglement.pdf';
import ps from '../downloads/SGWB-privacy-beleid.pdf';

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.footerInnerContainer}>
      <div className={styles.footerRow}>
        <nav className={styles.footerUtilNav}>
          <div>
            <span>&copy; 2019-2023 PRC Blaricum Eemnes Laren</span>
            <span> &nbsp;&nbsp;|&nbsp;&nbsp; </span>
            <a target="_blank" href={av}>Algemene voorwaarden</a>
            <span> &nbsp;&nbsp;|&nbsp;&nbsp; </span>
            <a target="_blank" href={ps}><nobr>Privacy Statement</nobr></a>
          </div>
          <div className={styles.footerDevelopers}>
            <a target="_blank" href="https://www.hollanddesigns.nl/">
              <nobr>Webdesign / Hollanddesigns Grafische vormgeving</nobr>
            </a>
            <span> &nbsp;&nbsp;|&nbsp;&nbsp; </span>
            <a target="_blank" href="https://statuscode.nl/">
              <nobr>Ontwikkeling / Status Code</nobr>
            </a>
          </div>
        </nav>
      </div>
    </div>
  </footer>
)

export default Footer
