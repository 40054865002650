import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import logo from '../images/logo-prc-bel.svg';
import styles from '../styles/header.module.scss';

const Header = ({ siteTitle, dateLabel, children }) => (
  <div
    className={styles.header}
  >
    <div
      className={styles.headerInnerContainer}
    >
      <div className={styles.logo}>
        <h1>
          <Link
            to='/'
          >
            <img src={logo} alt='PRC BEL' />
            <span className={styles.siteTitle}> {siteTitle}</span>
          </Link>
        </h1>
        <DateLabel label={dateLabel} />
      </div>
      {children}
    </div>
  </div>
);

const DateLabel = ({ label }) => {
  return (
    <div className={styles.dateLabel}>
      <p>{label}</p>
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
