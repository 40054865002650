import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header';
import Footer from './footer';
import { MobileFriendlyNavigation } from './navigation';

import '../styles/layout.scss';

const query = graphql`
  fragment NavigationItem on SanityNavigationItem {
    _key
    name
    url
    page {
      title
      slug {
        current
      }
    }
  }

  query LayoutQuery {
    siteConfig: sanitySite {
      title
      dateLabel
    }
    navigation: sanityNavigation {
      name
      items {
        ...NavigationItem
        items {
          ...NavigationItem
          items {
            ...NavigationItem
            items {
              ...NavigationItem
            }
          }
        }
      }
    }
  }
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={query}
    render={(data) => (
      <>
        <Header
          siteTitle={data.siteConfig.title}
          dateLabel={data.siteConfig.dateLabel}
        >
          <MobileFriendlyNavigation items={data.navigation.items} />
        </Header>
        <main>
          {children}
        </main>
        <Footer />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
