import React from 'react';
import { Link } from 'gatsby';
import { slide as Menu } from 'react-burger-menu'

import styles from '../styles/navigation.module.scss';
import '../styles/navigation.react-burger-menu.scss';

const MobileFriendlyNavigation = ({ items }) => (
  <>
    <div className={styles.desktop}>
      <NavigationMenu items={items} />
    </div>
    <div className={styles.mobile}>
      <Menu
        right
        width="195px"
      >
        <NavigationMenu items={items} />
      </Menu>
    </div>
  </>
)

const Navigation = ({ items }) => {
  return (
    <nav className={styles.desktop}>
      <NavigationMenu items={items} />
    </nav>
  );
}

const NavigationMenu = ({ items }) => {
  const navItems = items.map((item) => {
    // eslint-disable-next-line no-underscore-dangle
    const key = item._key;
    return (
      <li key={key} className={styles.navigationItem}>
        {item.page && item.page.slug ? (
          <NavigationLink
            name={item.name}
            title={item.page.title}
            to={`/${item.page.slug.current}`}
          />
        ) : (
          <a href={item.url} title={item.name}>
            {item.name}
          </a>
        )}
        {!!item.items.length && <NavigationMenu items={item.items} />}
      </li>
    );
  });

  return (
    <ul className={styles.navigation}>
      {navItems}
    </ul>
  );
};

const NavigationLink = ({ name, title, to }) => {
  return (
    <Link
      title={title}
      to={to}
    >
      <span>{name}</span>
    </Link>
  );
};

export default Navigation;
export { MobileFriendlyNavigation };
